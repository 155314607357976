.bgcolor {
  --bgcolor: #F3ECDC;
  background-color: var(--bgcolor);
}
.bgcolor--two {
  --bgcolor: #E5DEC8;
}
.bgcolor--three {
  --bgcolor: #F9F9F9;
}
.bgcolor--four {
  --bgcolor: #ffffff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/pernova_papierstruktur.jpg);
}
.bgcolor--five {
  --bgcolor: #84A579;
  --textcolor: #ffffff;
  --titlecolor: #ffffff;
  --accentcolor: #ffffff;
  --linkcolor: #ffffff;
}
.bgcolor--six {
  --bgcolor: #665852;
  --bgcolor-hov: #544843;
}
.catcolor {
  --catcolor: #665852;
}
.catcolor--one {
  --catcolor: #69CA81;
}
.catcolor--two {
  --catcolor: #73B8E7;
}
.catcolor--three {
  --catcolor: #F57979;
}
.catcolor--four {
  --catcolor: #FFA962;
}
.catcolor--five {
  --catcolor: #B891E1;
}
.catcolor--six {
  --catcolor: #C5B646;
}
:root {
  --sitecolor: #ABABAB;
  --textcolor: #665852;
  --titlecolor: #665852;
  --accentcolor: #665852;
  --linkcolor: #665852;
  --linkcolor-hov: #ABABAB;
  --spaceValue: 8px;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  --linkcolor-hov: var(--textcolor);
  line-height: 1.45454545;
  font-family: inherit;
  cursor: pointer;
  color: var(--linkcolor);
  padding-right: 45px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  font-size: 15px;
  line-height: 1.33333333;
  letter-spacing: 0.023em;
  font-weight: 600;
  position: relative;
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
.button:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 0px;
  width: 25px;
  height: 20px;
  background-color: var(--linkcolor);
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    color: var(--linkcolor-hov);
  }
  .button:hover:after,
  .button:focus:after {
    right: 0;
    background-color: var(--linkcolor-hov);
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation {
  float: left;
}
.navigation div.sub1 {
  --linkcolor-hov: var(--textcolor);
  display: flex;
  flex-direction: row;
  gap: clamp(30px, 3vw, 60px);
}
.navigation div.sub1 > .item > .menu {
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 600;
  position: relative;
}
.navigation div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: var(--textcolor);
  transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (hover: hover) and (pointer: fine) {
  .navigation div.sub1 > .item > .menu:hover:after,
  .navigation div.sub1 > .item > .menu:focus:after {
    width: 100%;
  }
}
.navigation div.sub1 > .item > .menu.path:after {
  width: 100%;
}
.layout1 h1 {
  font-size: clamp(5rem, 0.767rem + 6.61vw, 8.125rem);
}
.area--one {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 0 20px;
  margin-top: calc(var(--spaceTotal) * 3);
  margin-bottom: calc(var(--spaceTotal) * 3);
}
.area--one.area1 {
  margin-top: calc(var(--spaceTotal) * 1);
}
.layout4 .area--one.area2,
.layout3 .area--one.area2,
.layout2 .area--one.area2 {
  margin-top: calc(var(--spaceTotal) * 2);
}
.area--one.areaDonation {
  margin-top: var(--spaceTotal);
  margin-bottom: var(--spaceTotal);
}
.area--one .unit {
  grid-column: span 12;
}
.area--one .unitOne--1-1 .unit__body .part--wide {
  padding-right: 33.70786517%;
  box-sizing: border-box;
}
.area--one .unitOne--1-1 .unit__body .part--tiny {
  width: calc((100% - 40px) / 3);
}
@media (min-width: 1025px) {
  .area--one .unitOne--1-1-indentRight {
    padding-right: 24.15730337%;
    box-sizing: border-box;
  }
}
.area--one .unitOne--1-1-indentLeft {
  padding-left: 50.56179775%;
  box-sizing: border-box;
}
.area--one .unitOne--1-2 {
  grid-column: span 6;
}
.area--one .unitOne--1-2-indentRight {
  grid-column: span 6;
  padding-right: 17.04545455%;
  box-sizing: border-box;
}
.area--one .unitOne--1-3 {
  grid-column: span 4;
}
.area--one .unitOne--1-4 {
  grid-column: span 3;
}
.area--one .unitTwo {
  min-height: calc(var(--js-elementHeight, 400px) + (var(--spacePart) * 2));
  margin-top: calc(var(--spaceUnit) * 2 + 15px);
  margin-bottom: calc(var(--spaceUnit) * 2 + 15px);
}
.area--one .unitTwo.unitTwo--imageRight {
  box-sizing: border-box;
}
.area--one .unitTwo.unitTwo--imageRight .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--imageRight .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--imageRight .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--imageRight .part.pict.part--wide:last-child {
  position: absolute;
  top: 0;
  right: 0;
}
.area--one .unitTwo.unitTwo--imageLeft {
  box-sizing: border-box;
}
.area--one .unitTwo.unitTwo--imageLeft .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--imageLeft .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--imageLeft .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--imageLeft .part.pict.part--wide:last-child {
  position: absolute;
  top: 0;
  left: 0;
}
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageRight {
  padding-right: 58.98876404%;
}
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageRight .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageRight .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageRight .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageRight .part.pict.part--wide:last-child {
  width: 49.43820225%;
}
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageLeft {
  padding-left: 58.98876404%;
}
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageLeft .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageLeft .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageLeft .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantOne.unitTwo--imageLeft .part.pict.part--wide:last-child {
  width: 49.43820225%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight {
  padding-right: 42.13483146%;
  padding-left: 16.85393258%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageRight .part.pict.part--wide:last-child {
  width: 32.58426966%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft {
  padding-left: 42.13483146%;
  padding-right: 16.85393258%;
}
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:first-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.show.part--wide:last-child,
.area--one .unitTwo.unitTwo--variantTwo.unitTwo--imageLeft .part.pict.part--wide:last-child {
  width: 32.58426966%;
}
.area--one .unitThree {
  grid-column: span 6;
  padding-left: 17.04545455%;
  padding-right: 17.04545455%;
  box-sizing: border-box;
  padding-top: clamp(80px, 10vw, 185px);
}
.area--one .unitThree .unit__background {
  top: 0;
  left: 10%;
}
.area--one .unitFour {
  --spacePart: 10px;
}
.area--one .unitFour.unitFour--variantOne {
  grid-column: span 3;
}
.area--one .unitFour.unitFour--variantTwo {
  grid-column: span 8;
  display: grid;
  grid-template-columns: clamp(9.375rem, -1.63rem + 17.2vw, 17.5rem) 1fr;
  gap: 0 20px;
}
@media (min-width: 1800px) {
  .area--one .unitFour.unitFour--variantTwo {
    gap: 0 80px;
  }
}
.area--one .unit--form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.area--one .unit--form .unit__content {
  width: 880px;
  max-width: 100%;
}
.area--one .unit--form .unit__body {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 20px;
}
.area--one .unit--form .unit__body .part {
  grid-column: span 2;
}
.area--one .unit--form .unit__body .part--slim {
  grid-column: span 1;
}
/*# sourceMappingURL=./screen-large.css.map */